/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query malaga {
    file(relativePath: { eq: "parking-madrid-barajas-front.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parking Lotnisko Madryt-Barajas Hiszpania",
  tagline: "Madryt-Barajas, Hiszpania",
  featuredImage: "/assets/parking-madrid-barajas-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}
const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <strong>Adres:</strong> Av de la Hispanidad, s/n, 28042 Madrid,
        Hiszpania
        <p>
          Lotnisko Madryt-Barajas, będące jednym z kluczowych węzłów
          komunikacyjnych w Europie, obsługuje codziennie tysiące podróżnych.
          Dla tych, którzy decydują się podróżować samochodem, dostępność i
          bezpieczeństwo parkowania są kluczowe. Oto kompendium wiedzy na temat
          parkingów w okolicach lotniska Adolfo Suárez Madrid–Barajas.
        </p>
      </p>
    }
  >
    <h2 id="parkingi-przy-lotnisku-madryt-barajas">
      Parkingi przy lotnisku Madryt-Barajas - Dlaczego warto zarezerwować
      miejsce na parkingu?
    </h2>
    <ul>
      <li>
        <strong>Bezpieczeństwo:</strong> Monitoring 24/7 oraz ochrona zapewniają
        spokój umysłu.
      </li>
      <li>
        <strong>Oszczędność czasu:</strong> Brak konieczności szukania wolnego
        miejsca w dniu wylotu.
      </li>
      <li>
        <strong>Komfort:</strong> Bliskość do terminali oraz możliwość
        rezerwacji online.
      </li>
    </ul>
    <h2 id="rodzaje-parkingów-przy-lotnisku-madryt-barajas">
      Rodzaje parkingów przy lotnisku Madryt-Barajas
    </h2>
    <h3 id="parking-krótkoterminowy">Parking Krótkoterminowy</h3>
    <p>
      Idealny dla tych, którzy planują krótki postój. Znajduje się w
      bezpośredniej bliskości terminali, co umożliwia szybki dostęp do lotniska.
    </p>
    <h3 id="parking-długoterminowy">Parking Długoterminowy</h3>
    <p>
      Dedykowany dla podróżnych planujących dłuższy wyjazd. Mimo, że nieco dalej
      od terminali, oferuje atrakcyjne ceny oraz regularne transfery do
      lotniska.
    </p>
    <h3 id="parking-premium">Parking Premium</h3>
    <p>
      Dla tych, którzy cenią sobie luksus i wygodę. Bezpośredni dostęp do
      terminali, a także dodatkowe usługi takie jak valet parking.
    </p>
    <h2 id="procedura-rezerwacji">Procedura rezerwacji</h2>
    <ol>
      <li>
        <strong>Wybierz rodzaj parkingu:</strong> W zależności od Twoich
        potrzeb.
      </li>
      <li>
        <strong>Wprowadź daty:</strong> Określ datę i godzinę przyjazdu oraz
        wyjazdu.
      </li>
      <li>
        <strong>Dokonaj płatności:</strong> Bezpieczne metody płatności online.
      </li>
      <li>
        <strong>Otrzymaj potwierdzenie:</strong> E-mail z wszystkimi niezbędnymi
        informacjami.
      </li>
    </ol>
    <h2 id="ważne-wskazówki">Ważne wskazówki</h2>
    <ul>
      <li>
        <strong>Rezerwuj z wyprzedzeniem:</strong> Gwarantuje to dostępność oraz
        korzystniejsze ceny.
      </li>
      <li>
        <strong>Sprawdź lokalizację:</strong> Wybierz parking w zależności od
        terminalu, z którego korzystasz.
      </li>
    </ul>
    <h2 id="podsumowanie">Podsumowanie</h2>
    <p>
      Zaplanuj swój wyjazd z wyprzedzeniem i skorzystaj z możliwości rezerwacji
      miejsca parkingowego przy lotnisku Madryt-Barajas. Bezpieczeństwo, wygoda
      i atrakcyjne ceny czekają na Ciebie!
    </p>
    <hr />
    <p>
      Potrzebujesz więcej informacji na temat lotniska Madryt-Barajas? Odkryj{" "}
      <a href="link-do-innych-artyku%C5%82%C3%B3w">nasze artykuły</a>!
    </p>
    <p>
      <strong>Słowa kluczowe:</strong> parking lotnisko Madryt, Madryt-Barajas
      parking, miejsca parkingowe Madryt, rezerwacja parkingów Madryt.
    </p>

    <h1 id="podróżowanie-z-i-do-lotniska-adolfo-suárez-madridbarajas">
      Podróżowanie z i do lotniska Adolfo Suárez Madrid–Barajas
    </h1>
    <p>
      Planujesz podróż z lub na lotnisko Adolfo Suárez Madrid–Barajas?
      Zapraszamy do przeczytania naszego przewodnika, który dostarczy Ci
      wszystkich niezbędnych informacji dotyczących najważniejszego portu
      lotniczego w Hiszpanii.
    </p>
    <h2 id="kluczowe-informacje-o-lotnisku-madryt-barajas">
      Kluczowe informacje o lotnisku Madryt-Barajas:
    </h2>
    <ul>
      <li>
        <strong>Oficjalna nazwa:</strong> Lotnisko Adolfo Suárez Madryt -
        Barajas
      </li>
      <li>
        <strong>Nazwa międzynarodowa:</strong> Adolfo Suárez Madrid–Barajas
        Airport (Kod IATA: MAD)
      </li>
      <li>
        <strong>Lokalizacja:</strong> Av de la Hispanidad, s/n, 28042 Madrid,
        Hiszpania
      </li>
      <li>
        <strong>Liczba terminali:</strong> 4 (Terminale 1, 2 i 3 tworzą jeden
        kompleks; Terminal 4 położony jest ok. 3 km dalej, w dzielnicy Barajas)
      </li>
    </ul>
    <p>
      Dla tych, którzy podróżują samochodem, dostępny jest{" "}
      <strong>bezpłatny autobus wahadłowy</strong>. W ciągu dnia kursuje co 5-10
      minut, podczas gdy w nocy co 20-40 minut.
    </p>
    <h2 id="połączenia-z-polski">Połączenia z Polski:</h2>
    <p>
      Z Polski do Madrytu można dostać się dzięki linii lotniczej Ryanair z
      Krakowa i Modlina (Warszawa) oraz linii Wizzair i LOT z warszawskiego
      lotniska Chopina.
    </p>
    <h2 id="jak-szybko-i-tanio-dotrzeć-z-lotniska-do-centrum-madrytu">
      Jak szybko i tanio dotrzeć z lotniska do centrum Madrytu?
    </h2>
    <h3 id="1-metro---linia-8">
      1. <strong>Metro - Linia 8</strong>:
    </h3>
    <p>
      Madryckie metro jest jednym z najwygodniejszych środków transportu. Linia
      8 zatrzymuje się przy terminalach 2 i 4. Cała trasa od lotniska do stacji
      Nuevos Ministerios trwa około 15 minut. Cena biletu zaczyna się od 4,50€.
      Pamiętaj o dodatkowej opłacie za przejazd z lotniska!
    </p>
    <h3 id="2-pociągi-renfe">
      2. <strong>Pociągi RENFE</strong>:
    </h3>
    <p>
      Pociągami Renfe Cercanías można dostać się do centrum w ciągu około 30-40
      minut. Bilety dostępne są w automatach oraz na kasach, a cena wynosi
      2,60€.
    </p>
    <h3 id="3-autobusy-lotniskowe">
      3. <strong>Autobusy lotniskowe</strong>:
    </h3>
    <p>Autobusy firmy EMT kursują całą dobę. Cena biletu to 5€.</p>
    <h3 id="4-autobusy-miejskie">
      4. <strong>Autobusy miejskie</strong>:
    </h3>
    <p>
      Dla tych, którzy chcą dostać się na obrzeża Madrytu, idealne będą autobusy
      miejskie 101 i 200. Bilet kosztuje jedynie 1,50€.
    </p>
    <hr />
    <p>
      Planując podróż z lub na lotnisko w Madrycie, warto znać wszystkie
      dostępne opcje transportu. Dzięki temu podróż stanie się prostsza i
      bardziej komfortowa!
    </p>
    <p>
      <strong>Autobusy Podmiejskie</strong>:
    </p>
    <ul>
      <li>Linia 827: Tres Cantos-Canillejas</li>
      <li>Linia 828: Universidad Autónoma-Recintos Feriales</li>
      <li>Linia 822: Coslada-Lotnisko</li>
      <li>Linia 824: Lotnisko - Alcala de Henares</li>
    </ul>
    <p>
      <strong>Autobusy Międzymiastowe</strong>:
    </p>
    <ul>
      <li>
        <strong>Alsa</strong>: Połączenia z miastami takimi jak Saragossa,
        Barcelona, Valladolid i wiele innych.
      </li>
      <li>
        <strong>Avanzabus</strong>: Połączenia z Avila, Castellón, Salamanka i
        innymi.
      </li>
      <li>
        <strong>Socibus</strong>: Łączy lotnisko z głównymi miastami Andaluzji.
      </li>
    </ul>
  </Homepage>
)

export default HomePage
